@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

.portfolio-container {
  font-family: 'Noto Sans JP', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.portfolio-container img {
  width: 30%;
  cursor: pointer;
}

.portfolio-item {
  flex-basis: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
