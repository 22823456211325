.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
  }
  
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    border: 2px solid #333;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    width: 500px;
    height: 200px;
    justify-content: center;
  }
  
  .link:hover {
    background-color: #333;
    color: #fff;
  }
  
  .icon {
    margin-right: 10px;
    width: 100%;
    height: 100%;
  }
  