/* Profile.css */
.profile {
    font-family: 'Noto Sans CJK', sans-serif;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    text-align: left; /* 追加 */
  }
  
  .profile-title {
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px solid #333;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
  
  .profile-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .profile-list li {
    margin-bottom: 1rem;
  }
  
  .profile-list li p {
    margin: 0;
  }
  
  .profile-list li ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  
  .profile-list li ul li {
    margin-bottom: 0.5rem;
  }
  